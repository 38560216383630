<template>
  <AppMainContainer center-class="offer">
    <template #subheader>
      <PageHeader :isAdmin="!isOrganizationRoot && !officeExists" />
    </template>
    <template #center>
      <h1 class="mb-3">
        Инциденты
      </h1>
      <div
        v-if="!loader && incident"
        class="incident-item incident-item--page"
      >
        <div class="incident-item__title">
          <IconAlert class="mr-3" />
          Инцидент: {{ incident.type.title }}
        </div>
        <div class="incident-item__sub-title">
          <IconEmployee class="mr-2" />
          <b class="mr-auto">
            {{ incident.vacancy.title }}
          </b>
          <div class="d-flex align-items-center border-right pr-3 mr-3">
            <div class="incident-item__sub-title-text-block mr-3">
              Плановые:
              <div class="text-gray">
                ФОТ + налоги
              </div>
            </div>
            <div class="font-weight-bold">
              <AppTextMoney :money="incident.offer.amount" /> + <AppTextMoney
                :money="incident.offer.tax"
                class="text-gray"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="incident-item__sub-title-text-block mr-3">
              Фактические:
              <div class="text-gray">
                ФОТ + налоги
              </div>
            </div>
            <div class="font-weight-bold">
              <AppTextMoney :money="incident.relay.pay" /> + <AppTextMoney
                :money="incident.relay.tax"
                class="text-gray"
              />
            </div>
          </div>
        </div>
        <div class="incident-item__line incident-item__line--shift">
          <span class="mr-auto">Плановые</span>
          <span class="border-right pr-3 mr-3">
            Начало смены: {{ incident.offer.startDate }} <b>{{ incident.offer.startTime }}</b>
          </span>
          <span class="mr-5">
            Конец смены: {{ incident.offer.endDate }} <b>{{ incident.offer.endTime }}</b>
          </span>
          <div class="shift-hours">
            {{ incident.offer.duration }}
          </div>
        </div>
        <div
          v-if="parseFloat(incident.relay.duration) > 0"
          class="incident-item__line incident-item__line--shift"
        >
          <span class="mr-auto">Фактические</span>
          <span class="border-right pr-3 mr-3">
            Начало смены: {{ incident.relay.startDate }} <b>{{ incident.relay.startTime }}</b>
          </span>
          <span class="mr-5">
            Конец смены: {{ incident.relay.endDate }} <b>{{ incident.relay.endTime }}</b>
          </span>
          <div class="shift-hours shift-hours--green">
            {{ incident.relay.duration }}
          </div>
        </div>
        <div class="incident-item__user-card">
          <div class="incident-item__laborer">
            Исполнитель:
          </div>
          <OfferItemChatUserCard
            v-if="incident.laborer"
            :selectedUser="null"
            :noBorder="true"
            :laborer="incident.laborer"
          />
        </div>
        <div class="incident-item__line ">
          <span>Создатель оффера: <b>{{ incident.offer.createdBy.fullName }}</b></span>
        </div>
        <div class="incident-item__line flex-column">
          <div class="mb-2">
            <b class="mr-1">
              Должностные обязанности:
            </b>
            <span
              class="collapse-string"
              @click="isCompetenceListShown = !isCompetenceListShown"
            >
              {{ isCompetenceListShown ? 'Свернуть' : 'Развернуть' }}
            </span>
          </div>
          <ul v-if="isCompetenceListShown">
            <li
              v-for="it in incident.vacancy.competenceList"
              :key="it.id"
            >
              {{ it.title }}
            </li>
          </ul>
        </div>
        <div class="incident-item__line ">
          <span>Температурный режим на объекте: <b>{{ incident.offer.weatherCondition }}</b></span>
        </div>
        <div
          v-if="incident.offer.hasTransportation || incident.offer.freeFood"
          class="incident-item__line flex-column"
        >
          <div class="incident-item__offer-feature mb-3">
            <IconCheck
              v-if="incident.offer.hasTransportation"
              class="incident-item__offer-feature-icon"
            />
            <b>
              Корпоративный транспорт
            </b>
          </div>
          <div class="incident-item__offer-feature">
            <IconCheck
              v-if="incident.offer.freeFood"
              class="incident-item__offer-feature-icon"
            />
            <b>
              Бесплатный обед
            </b>
          </div>
        </div>
        <div class="incident-item__line align-items-center">
          <IconFileDoc
            :width="13"
            :height="17"
            class="mr-2"
            style="vertical-align: middle;text-align: center"
          />
          <b class="mr-1">Акт выполненнных работ</b>
          <a
            :href="incident.relay.file.path"
            class="download-link"
            target="_blank"
          >
            (Скачать)
          </a>
        </div>
        <div
          class="incident-item__line incident-item__line--description"
          :class="{'border-bottom': incident.mediaList && incident.mediaList.length}"
        >
          <h2 class="mb-3">
            Описание инцидента
          </h2>
          <div class="incident-item__text-description">
            {{ incident.description }}
          </div>
          <div
            v-if="incident.mediaList && incident.mediaList.length"
            class="d-flex"
          >
            <a
              v-for="it in incident.mediaList"
              :key="it.id"
              :href="it.path"
              class="attached-file"
              target="_blank"
            >
              <IconFile
                :width="30"
                :height="30"
                class="mr-3"
                style="vertical-align: middle;text-align: center"
              />
              <div>
                <div class="attached-file__name">
                  234m42k.jpg
                </div>
                <div class="attached-file__size">
                  1.2 MB
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="incident-item__line incident-item__line--description">
          <h2 class="mb-3">
            Описание решения
          </h2>
          <div class="incident-item__text-description">
            {{ incident.solution.description }}
          </div>
        </div>
        <div class="incident-item__line flex-column">
          <h2 class="mb-3">
            Принятые меры
          </h2>
          <span class="mb-3">К соискателю: <b>{{ incident.solution.laborerPenalty.type.title }}</b></span>
          <span>К работодателю: <b>{{ incident.solution.clientPenalty.type.title }}</b></span>
        </div>
        <div class="incident-item__footer">
          <b class="incident-item__number mr-3">{{ incident.relay.id.slice(-6) }}</b>
          <span class="mr-1">{{ incident.startDate }}</span>
          <b class="mr-auto">{{ incident.startTime }}</b>
          <span>{{ incident.status }}</span>
        </div>
      </div>
      <AppLoader
        v-else
        class="full-height"
      />
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import IconEmployee from '@/components/common/icons/IconEmployee'
import IconAlert from '@/components/common/icons/IconAlert'
import IconCheck from '@/components/common/icons/IconCheck'
import IconFileDoc from '@/components/common/icons/IconFileDoc'
import IconFile from '@/components/common/icons/IconFile'
import AppLoader from '@/components/AppLoader'
import AppTextMoney from '@/components/common/AppTextMoney'
import { mapActions, mapState } from 'vuex'
import userUtil from '@/utils/user-util'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  name: 'IncidentItem',
  components: {
    PageHeader,
    AppMainContainer,
    OfferItemChatUserCard,
    IconEmployee,
    IconAlert,
    IconCheck,
    IconFileDoc,
    IconFile,
    AppLoader,
    AppTextMoney
  },
  data: () => ({
    isCompetenceListShown: false,
  }),
  computed: {
    ...mapState({
      loader: (state) => state.incident.loader,
    }),
    incidentId () {
      return this.$route.params.incidentId
    },
    getUser () {
      return this.$store.state.office.items
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.getUser)
    },
    incident () {
      return this.$store.state.incident.item
    },
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
  },
  watch: {
    officeExists (val, prev) {
      if (val && !prev) {
        this.fetchIncidentItem(this.incidentId)
      }
    },
  },
  async created () {
    if (this.officeExists) {
      this.fetchIncidentItem(this.incidentId)
    }
  },
  methods: {
    ...mapActions({
      fetchIncidentItem: 'incident/fetchIncidentItem',
    }),
  }
}
</script>

<style lang="sass">
.incident-item
  padding: 15px 30px
  margin-bottom: 30px
  background: #FFFFFF
  border: 1.5px solid rgba(0, 0, 0, 0.1)
  box-sizing: border-box
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
  border-radius: 6px
  cursor: pointer

  &--page
    cursor: default

  &__title,
  &__sub-title,
  &__user-card,
  &__line
    display: flex
    align-items: center
    padding: 15px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  &__line
    align-items: baseline
    font-weight: 500
    font-size: 14px
    line-height: 17px
    color: rgba(0, 0, 0, 0.7)

    &--shift
      font-weight: 600
      font-size: 16px
      line-height: 20px

    &--description
      flex-direction: column
      border: none

  &__user-card
    flex-direction: column
    align-items: start
    padding-bottom: 0

  &__laborer
    margin-top: 10px
    margin-bottom: -5px
    font-size: 14px
    opacity: 0.7

  &__footer
    display: flex
    padding: 15px 0
    font-size: 16px
    line-height: 20px

    b, span
      opacity: 0.2

    b.incident-item__number
     opacity: 0.4


  &__title
    font-weight: 800
    font-size: 20px
    line-height: 24px
    font-size: 16px
    line-height: 20px

  &__shift-date
    color: rgba(0, 0, 0, 0.7)

  &__sub-title-text-block
    font-weight: 600
    font-size: 12px
    line-height: 15px
    color: rgba(0, 0, 0, 0.7)

  &__text-description
    width: 100%
    padding: 14px 20px
    min-height: 100px
    border-radius: 15px
    border: 1px solid rgba(155, 155, 155, 0.2)
    font-size: 12px
    line-height: 15px

  &__offer-feature
    position: relative
    padding-left: 30px

  &__offer-feature-icon
    position: absolute
    left: 0
    top: 50%
    margin-top: -6px

</style>
