<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M0 5C0 2.23858 2.23858 0 5 0H25C27.7614 0 30 2.23858 30 5V25C30 27.7614 27.7614 30 25 30H5C2.23858 30 0 27.7614 0 25V5ZM5 5H25V7H5V5ZM25 11H5V13H25V11ZM5 17H25V19H5V17ZM16 23H5V25H16V23Z" fill="black"/>
  </svg>

</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconFile",
        mixins: [icon_config],
    }
</script>

<style scoped>

</style>
