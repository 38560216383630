<template>
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.7" d="M15.4999 2.00015L14.0857 0.585938L5.50003 9.17163L1.91434 5.58594L0.500122 7.00015L5.49994 12.0002L15.4999 2.00015Z" fill="black"/>
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconCheck",
        mixins: [icon_config],
    }
</script>

<style scoped>

</style>
